<template>
  <div :class="`rai-app-logo rai-app-logo--${this.$vuetify.breakpoint.name}`">
    <rai-spinner
      v-if="$vuetify.breakpoint.mdOnly"
      :loading.sync="this.$store.state.ui.loading"
      :height="imgHeight"
      :icon-color="color"
    />
    <rai-logo v-else :height="imgHeight" :fill="color" />
  </div>
</template>

<script>
import RaiLogo from "@/components/RaiLogo";
import RaiSpinner from "@/components/util/RaiSpinner";

export default {
  components: {
    RaiLogo,
    RaiSpinner,
  },
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
  },
  computed: {
    imgHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "32";
        case "sm":
          return "32";
        case "md":
          return "42";
        case "lg":
          return "36";
        case "xl":
          return "36";
      }
      return "42";
    },
  },
};
</script>

<style scoped>
.rai-app-logo {
  position: relative;
  left: 0;
  top: 0;
}

.rai-app-logo--xs {
  height: 48px;
  max-width: 140px;
}

.rai-app-logo--xs svg {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.rai-app-logo--sm {
  height: 48px;
  max-width: 140px;
}
.rai-app-logo--sm svg {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.rai-app-logo--md {
  height: 48px;
  width: 60px;
  margin-left: -24px;
}

.rai-app-logo--md svg {
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.rai-app-logo--lg {
  height: 64px;
  width: 170px;
  margin-right: 24px;
}
.rai-app-logo--lg svg {
  margin-left: 0px;
  margin-top: 12px;
}

.rai-app-logo--xl {
  height: 64px;
  width: 194px;
}
.rai-app-logo--xl svg {
  margin-left: 0px;
  margin-top: 12px;
}
</style>
