<template>
  <v-list :dense="!$vuetify.breakpoint.mdOnly">
    <v-divider />
    <SupportWidget
      v-slot="{ toggle, visible }"
      :offset-x="$vuetify.application.left"
    >
      <v-list-item :input-value="visible" @click="toggle">
        <v-list-item-action>
          <v-icon v-if="visible">$cancel</v-icon>
          <v-icon v-else>$help</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </SupportWidget>
  </v-list>
</template>

<script>
import SupportWidget from "@/components/support/SupportWidget";
export default {
  name: "RaiNavExtraList",
  components: {
    SupportWidget,
  },
};
</script>
