import { defineFeatureComponent } from "@/core-ui";
import Freshdesk from "@/components/support/Freshdesk.vue";

export default defineFeatureComponent({
  name: "SupportWidget",
  features: [
    {
      feature: "support",
      variant: "freshdesk",
      component: Freshdesk,
    },
  ],
});
