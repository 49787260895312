import gql from "graphql-tag";
import { parseIfJson, camelize } from "@/utils/lib";
import store from "@/store";

const storeChannelName = () => store.getters["sockets/storeChannelName"];

// Registers an event handler that listens to
// `timeclock-punch` events. When an event is received,
// the handler updates the apollo cache for the employee
// if the clockedIn property on the cache and data don't match.
export const initTimeclockSubscription = async (pusher, apollo) => {
  const channel = await pusher.channel(storeChannelName());

  // TODO - rename this to employee-update or similar action
  // - because this is what we should do
  // - and the same subscription can be used then to do update of employee for ex. when creating them from homebase/when i work
  channel &&
    channel.bind("timeclock-punch", (data) => {
      // Destructure the response to get the employee object
      const { employee } = camelize(parseIfJson(data));

      const id = `Employee:${employee.id}`;
      const fragment = gql`
        fragment e on Employee {
          clockedIn
          isOnBreak
        }
      `;
      // Read the employee fragment from the apollo cache
      const employeeFragment = apollo.readFragment({
        id,
        fragment,
      });
      // If the read was successful and the data and fragment
      // don't match, update the cache
      if (
        employeeFragment &&
        (employeeFragment.clockedIn !== employee.clockedIn ||
          employeeFragment.isOnBreak !== employee.isOnBreak)
      ) {
        const data = {
          __typename: "Employee",
          clockedIn: employee.clockedIn,
          isOnBreak: employee.isOnBreak,
        };
        apollo.writeFragment({
          id,
          fragment,
          data,
        });
        if (store.state.teamMode.employee.id == employee.id) {
          store.commit(
            "teamMode/setEmployee",
            {
              ...store.state.teamMode.employee,
              clockedIn: employee.clockedIn,
              isOnBreak: employee.isOnBreak,
            },
            { root: true }
          );
        }
      }
    });
};
