<script>
import RaiNavListTile from "@/components/RaiNavListTile";
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import { hasCurrentStoreAccess } from "@/mixins/store";

export default {
  components: { RaiNavListTile },
  mixins: [hasCurrentStoreAccess],
  props: {
    store: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    messageThreads: [],
  }),
  apollo: {
    messageThreads: {
      query: gql`
        query RAI_NAV_LIST_MESSAGE_THREADS(
          $storeId: ID!
          $input: MessageThreadFilterInputObject
        ) {
          messageThreads(storeId: $storeId, input: $input) {
            id
            unread
          }
        }
      `,
      variables() {
        return {
          storeId: this.storeId,
          input: { search: null },
        };
      },
      fetchPolicy: "cache-first",
    },
  },
  computed: {
    // TODO - try to transform to prop
    storeId() {
      return this.$route.params.storeId;
    },
    ...mapGetters("auth", ["isManager"]),
    navItems() {
      const navItemsList = [
        {
          icon: "$vuetify.icons.raiDaybook",
          title: "Daybook",
          // TODO - daybook route should be changed so that it can be used properly with new methodology
          routeName: "daybook",
          date: true,
          dateType: "day",
          managerOnly: false,
        },
        {
          icon: "$vuetify.icons.raiBuys",
          title: "Buys",
          routeName: "buys_home",
          routeParams: {
            query: {
              buysTab: "open",
            },
          },
          date: false,
          managerOnly: false,
        },
        {
          icon: "$vuetify.icons.raiBackstock",
          title: "Backstock",
          routeName: "backstock_home",
          date: false,
          managerOnly: false,
        },
        {
          icon: "$raiTexting",
          title: "Texting",
          routeName: "texting_home",
          count: this.unreadCount,
          date: false,
          managerOnly: false,
        },
        {
          icon: "$raiCustomer",
          title: "Customers",
          routeName: "customers_home",
          date: false,
          managerOnly: false,
        },
        {
          icon: "$raiBounceback",
          title: "Bounceback",
          routeName: "bounceback_home",
          managerOnly: false,
        },
        {
          icon: "$vuetify.icons.raiTeam",
          title: "Team",
          routeName: "team.home",
          date: false,
        },
        {
          icon: "$raiReports",
          title: "Reports",
          routeName: "reports.home",
          date: false,
          managerOnly: false,
        },
        {
          icon: "$vuetify.icons.raiInventory",
          title: "Inventory",
          routeName: "inventory_home",
          date: false,
          managerOnly: false,
        },
        this.showOrders && {
          icon: "$raiOrders",
          title: "Orders",
          routeName: "orders_home",
          date: false,
          managerOnly: false,
        },
      ];

      return navItemsList.filter((item) => !!item);
    },
    allowedNavItems() {
      return this.navItems.filter(
        (item) => !item.managerOnly || (item.managerOnly && this.isManager)
      );
    },
    unreadCount() {
      return this.messageThreads.reduce((count, message) => {
        if (message.unread) {
          return count + 1;
        } else {
          return count;
        }
      }, 0);
    },
    showOrders() {
      return !!this.store?.integrationShopify;
    },
  },
};
</script>

<template>
  <v-list
    class="rai-nav-list"
    :dense="!$vuetify.breakpoint.mdOnly"
    :shaped="$vuetify.breakpoint.lgAndUp"
  >
    <rai-nav-list-tile
      v-for="item in allowedNavItems"
      :key="item.title"
      :item="item"
      :store-id="storeId"
    />
  </v-list>
</template>

<style>
.v-navigation-drawer--mini-variant .rai-nav-list {
  padding-top: 0;
}

.icon-wrapper.badge .v-badge__badge {
  transform: scale(0.85);
}
</style>
