<template>
  <v-app
    v-if="store?.storeNumber"
    :key="store?.storeNumber"
    :class="`rai-app-${$vuetify.breakpoint.name}`"
  >
    <RaiNotice />
    <RaiNavDrawer :store="store" />
    <router-view name="appbar"></router-view>
    <v-main>
      <router-view></router-view>
    </v-main>
    <router-view name="dialog"></router-view>
    <router-view name="bardcodeScan"></router-view>
    <RaiSnackbar />
    <TeamMode v-if="isTeamMode" />
  </v-app>
</template>

<script>
import RaiNavDrawer from "@/components/RaiNavDrawer";
import { RaiPusher, initTimeclockSubscription } from "@/utils/sockets/index";
import { STORE_EMPLOYEES } from "@/graphql/employee/queries.gql";
import { STORE, STORE_SELECT_STORES } from "@/graphql/store/queries.gql";
import { hasActiveEmployeeAccess } from "@/mixins/employee";
import { mapMutations, mapGetters } from "vuex";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { drsNotConnectedNotice } from "@/utils/notices";
import { findMetaField } from "@/utils/router";

import { format } from "date-fns";
import { SHIFTS_TODAY } from "@/graphql/shift/queries.gql";

// add and initialize Pusher
// add and initialize Chat
// setup RaiPos
export default {
  name: "StoreLayout",
  components: {
    RaiNotice: () =>
      import(/* webpackChunkName: "appmsg" */ "@/components/util/RaiNotice"),
    RaiSnackbar: () =>
      import(/* webpackChunkName: "appmsg" */ "@/components/util/RaiSnackbar"),
    RaiNavDrawer,
    TeamMode: () =>
      import(/* webpackChunkName: "team-mode" */ "@/components/TeamMode.vue"),
  },
  mixins: [hasActiveEmployeeAccess, hasSnackbarAccess],
  props: {
    storeId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loaded: false,
      store: null,
      stores: null,
    };
  },
  provide() {
    return {
      computedActiveEmployeeCan: () => this.computedActiveEmployeeCan,
      computedActiveEmployee: () => this.computedActiveEmployee,
      computedEmployees: () => this.employees,
      refetchEmployees: () => this.$apollo.queries.employees.refetch(),
      currentStore: () => this.store,
      getShifts: () => this.shifts,
      getStores: () => this.stores,
    };
  },
  apollo: {
    shifts: {
      query: SHIFTS_TODAY,
      variables() {
        return {
          storeId: this.storeId,
          businessDate: format(new Date(), "yyyy-MM-dd"),
        };
      },
      skip() {
        return !this.storeId;
      },
    },
    employees: {
      query: STORE_EMPLOYEES,
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      skip() {
        return !this.storeId;
      },
    },
    store: {
      query: STORE,
      variables() {
        return {
          id: this.storeId,
        };
      },
      skip() {
        return !this.storeId;
      },
    },
    stores: {
      query: STORE_SELECT_STORES,
    },
  },
  computed: {
    ...mapGetters("sockets", ["storeChannelName"]),
    ...mapGetters("auth", ["isTeamMode"]),
    ...mapGetters("auth", ["isTeamModeLocked"]),

    title() {
      return this.$route.meta.title;
    },
    isRaiPos() {
      // we are "in a pos" if we are in a pos OR if we are in team mode not on a pos
      return (!!this.$raiPos && !this.$raiPos.isPosProxy) || this.isTeamMode;
    },
  },
  watch: {
    storeId() {
      // we could call this.$options.mounted()?
      this.onMounted();
    },
    store: {
      handler(value) {
        if (
          process.env.NODE_ENV === "development" &&
          window?.localStorage?.getItem("RAI:TEST_POS") == "true"
        ) {
          this.$raiPos.storeNumber = value.storeNumber;
        }

        value.warnDrs
          ? drsNotConnectedNotice.show()
          : drsNotConnectedNotice.clear();
      },
    },
    isTeamModeLocked(v) {
      if (v && findMetaField(this, "requiresManager")) {
        this.$router.push({ name: "daybook" });
      }
    },
  },
  mounted() {
    this.onMounted();
  },
  async beforeDestroy() {
    // unsubscribe Drs Warn Updates
    const channel = await this.getStoreChannel();
    channel.unbind("open", this.handleDrsWarning);

    // Clear notices
    drsNotConnectedNotice.clear();
  },
  methods: {
    ...mapMutations("auth", ["setActiveStore"]),
    initPusher() {
      RaiPusher.unsubscribeAll(this.$pusher);
      RaiPusher.subscribeAll(
        this.$pusher,
        this.$apollo.provider.defaultClient,
        this.isRaiPos
      );
      this.subscribeToDrsWarnUpdates();
      this.subscribePunchclockUpdates();
    },
    waitForPusher() {
      return new Promise((resolve) => {
        let interval = setInterval(() => {
          if (this.$pusher) {
            clearInterval(interval);
            resolve(true);
          }
        }, 500);
      });
    },
    async getStoreChannel() {
      let channel = this.$pusher.channel(this.storeChannelName);
      return channel || this.$pusher.subscribe(this.storeChannelName);
    },
    async onMounted() {
      this.setActiveStore(
        this.storeId || this.$store.getters["auth/activeStoreId"]
      );

      // init pusher
      this.initPusher();

      this.loaded = true;
    },
    async subscribeToDrsWarnUpdates() {
      await this.waitForPusher();
      const channel = await this.getStoreChannel();

      channel.bind("drs-warning", this.handleDrsWarning);
    },
    async subscribePunchclockUpdates() {
      const cache = this.$apollo.getClient();
      await this.waitForPusher();
      await initTimeclockSubscription(this.$pusher, cache);
    },

    handleDrsWarning(data) {
      if (data) {
        drsNotConnectedNotice.show();
      }
    },
  },
};
</script>
