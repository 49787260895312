/**
 * @typedef {Object} PosVersion
 * @property {number} major
 * @property {number} minor
 * @property {number} patch
 * @property {number} build
 */

export const NEW_POS_BUILD_MIN_VERSION = "23.12.0.10001";

/**
 * Parses a POS Version string into a structure containing each part of the version
 * @param {string} versionStr The four-part version to parse, i.e. `"24.1.23.10015"`
 * @returns {PosVersion}
 */
function parsePosVersion(versionStr) {
  const [major, minor = 0, patch = 0, build = 0] = versionStr.split(".");

  if (!major) {
    throw new Error("Must at least specify build year");
  }

  return {
    major,
    minor,
    patch,
    build,
  };
}

/**
 * Compares two `PosVersion` objects
 * @param {PosVersion} a
 * @param {PosVersion} b
 * @returns {boolean} 1 if a > b, -1 if a < b, 0 if a = b
 */
function comparePosVersions(a, b) {
  // first check major
  if (a.major > b.major) {
    return 1;
  } else if (b.major > a.major) {
    return -1;
  }

  // major is equal, check minor
  if (a.minor > b.minor) {
    return 1;
  } else if (b.minor > a.minor) {
    return -1;
  }

  // minor is equal, check patch
  if (a.patch > b.patch) {
    return 1;
  } else if (b.patch > a.patch) {
    return -1;
  }

  // patch is equal, check build
  if (a.build > b.build) {
    return 1;
  } else if (b.build > a.build) {
    return -1;
  }

  // these two versions are equal
  return 0;
}

export async function minPosVersion(versionStr) {
  if (!window.raiPosAsync) return;

  const currentVersionStr = await window.raiPosAsync.raiPosVersion();
  const currentVersion = parsePosVersion(currentVersionStr);
  const testVersion = parsePosVersion(versionStr);

  return comparePosVersions(testVersion, currentVersion) >= 0;
}

export async function maxPosVersion(versionStr) {
  if (!window.raiPosAsync) return;

  const currentVersionStr = await window.raiPosAsync.raiPosVersion();
  const currentVersion = parsePosVersion(currentVersionStr);
  const testVersion = parsePosVersion(versionStr);

  return comparePosVersions(testVersion, currentVersion) <= 0;
}

export async function exactPosVersion(versionStr) {
  if (!window.raiPosAsync) return;

  const currentVersionStr = await window.raiPosAsync.raiPosVersion();
  const currentVersion = parsePosVersion(currentVersionStr);
  const testVersion = parsePosVersion(versionStr);

  return comparePosVersions(testVersion, currentVersion) === 0;
}
