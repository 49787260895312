<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="iconSize"
    :height="iconSize"
    viewBox="0 0 512 512"
  >
    <title>ResaleAI</title>
    <g id="Arrows" data-name="Arrows" :class="arrowClass" :fill="iconColor">
      <path
        d="M 391.8 328.7 l -0.4 0.7 q -1.8 3.3 -3.7 6.4 C 384.5 341.1 381 346.2 377.2 351.1 q -2.3 2.9 -4.7 5.7 c -1.6 1.9 -3.3 3.7 -5 5.5 q -5.1 5.4 -10.7 10.2 c -0.9 0.8 -1.9 1.6 -2.8 2.4 q -4.3 3.5 -8.8 6.8 c -3 2.1 -6.1 4.2 -9.3 6.1 q -6.4 3.9 -13.1 7.1 q -1.7 0.8 -3.4 1.6 q -5.1 2.3 -10.4 4.3 c -1.2 0.4 -2.4 0.8 -3.6 1.3 q -5.4 1.8 -10.9 3.2 C 269.2 411.6 242.7 411.6 217.5 405.1 q -5.5 -1.4 -10.9 -3.2 c -1.2 -0.4 -2.4 -0.8 -3.6 -1.3 q -5.3 -1.9 -10.4 -4.3 q -1.7 -0.8 -3.4 -1.6 q -6.7 -3.2 -13.1 -7.1 c -3.2 -1.9 -6.3 -4 -9.3 -6.1 s -6 -4.4 -8.8 -6.8 c -0.9 -0.8 -1.9 -1.6 -2.8 -2.4 q -5.6 -4.8 -10.7 -10.2 c -1.7 -1.8 -3.3 -3.6 -5 -5.5 s -3.2 -3.8 -4.7 -5.7 C 130.9 346.2 127.4 341.1 124.2 335.9 q -1.9 -3.2 -3.7 -6.4 q -2.5 -4.6 -4.7 -9.4 c -0.1 -0.2 -0.2 -0.4 -0.3 -0.6 c -1 -2.3 -2 -4.6 -2.9 -6.9 C 101.1 283.2 98.8 251.1 106 220.5 L 43.3 279.4 L 25.6 296 L 0 320 H 51.8 q 3.1 9.8 7.1 19.3 C 98.4 433.2 198.7 486.1 298.5 465.8 C 398.3 445.6 470.1 357.8 470 256 c 0 -0.2 0 -0.4 0 -0.6 Z"
      ></path>
      <path
        d="M 460.3 192 q -3.1 -9.8 -7.1 -19.3 C 413.6 78.8 313.3 25.9 213.5 46.2 C 113.7 66.4 41.9 154.2 42 256 c 0 0.2 0 0.4 0 0.6 l 78.2 -73.3 c 1.3 -2.4 2.7 -4.8 4.1 -7.2 c 1.9 -3.2 4 -6.3 6.1 -9.3 c 2.9 -4 5.9 -7.9 9.1 -11.6 C 144.4 149.6 149.6 144.4 155.2 139.6 c 3.7 -3.2 7.6 -6.3 11.6 -9.1 c 3 -2.1 6.1 -4.2 9.3 -6.1 q 6.3 -3.9 13.1 -7.1 l 3.4 -1.6 q 5.1 -2.3 10.4 -4.3 c 1.2 -0.4 2.4 -0.8 3.6 -1.3 q 5.4 -1.8 10.9 -3.2 C 242.8 100.4 269.2 100.4 294.5 106.8 q 5.5 1.4 10.9 3.2 c 1.2 0.4 2.4 0.8 3.6 1.3 q 5.3 2 10.4 4.3 l 3.4 1.6 q 6.7 3.2 13.1 7.1 c 3.2 1.9 6.3 4 9.3 6.1 c 4 2.9 7.9 5.9 11.6 9.1 C 362.4 144.4 367.6 149.6 372.5 155.2 c 3.2 3.7 6.3 7.6 9.1 11.6 c 2.1 3 4.2 6.1 6.1 9.3 q 3.9 6.3 7.1 13.1 c 0.4 0.9 0.9 1.8 1.3 2.8 c 0.1 0.2 0.2 0.4 0.3 0.6 q 2.3 5.1 4.3 10.4 c 0.4 1.2 0.8 2.4 1.3 3.6 c 1 3.1 2 6.2 2.8 9.4 c 0.1 0.5 0.3 1 0.4 1.5 C 411.4 241.8 411.6 267.1 405.9 291.5 l 62.8 -58.9 L 486.4 216 L 512 192 Z"
      ></path>
    </g>
    <g id="AI" data-name="AI" :fill="iconColor">
      <path
        d="M 293.9 299.5 v -53 c 0 -14 -3.4 -25.9 -10.1 -35.5 a 68.9 68.9 0 0 0 -21.1 -19.4 H 366.8 V 312.1 c 0 6 3.4 10.2 10 12.6 a 138.9 138.9 0 0 0 -16.6 -160.6 a 40.8 40.8 0 0 1 -2.8 3.1 A 39.2 39.2 0 0 1 345 175.6 a 39.2 39.2 0 0 1 -30.2 0 A 39.3 39.3 0 0 1 294 154.8 a 39.2 39.2 0 0 1 0 -30.2 c 0.3 -0.7 0.6 -1.3 1 -2 A 139 139 0 0 0 128.8 200 c 0.3 -0.2 0.6 -0.4 0.9 -0.6 q 11.1 -6.5 27 -10.3 a 155.5 155.5 0 0 1 36.1 -3.8 a 186.4 186.4 0 0 1 32 2.8 a 102.2 102.2 0 0 1 29.5 9.7 c 1.7 0.9 3.3 1.9 4.8 2.9 c 1 0.7 2.1 1.4 3.1 2.1 a 58.9 58.9 0 0 1 13.8 13.8 a 43.4 43.4 0 0 1 6.1 12.9 h 0 a 53.8 53.8 0 0 1 1.7 8 a 0.5 0.5 0 0 0 0 0.1 c 0.2 1.3 0.3 2.6 0.4 3.9 c 0 0.2 0 0.3 0 0.5 c 0.1 1.5 0.1 3 0.1 4.5 v 55.3 c -0.1 4.1 -1 37.2 9.4 61.1 a 46.2 46.2 0 0 1 -9.3 1.5 l -0.3 0 l -2.3 0.2 l -0.9 0 l -1.7 0.1 l -1.2 0 l -1.4 0 h -4.7 l -4.2 -0.1 l -0.5 0 l -1.3 0 h -0.5 l -1 0 l -0.4 0 l -1.9 -0.1 C 249 364 240.3 359.9 235 355.9 q -11.8 -8.9 -16.4 -25 a 66.3 66.3 0 0 1 -8.1 13.8 a 52.9 52.9 0 0 1 -12.6 11.9 a 64.3 64.3 0 0 1 -18.1 8.4 a 68.3 68.3 0 0 1 -7.9 1.8 a 138.9 138.9 0 0 0 173.4 -4.3 h -41 C 292.2 338.4 293.9 299.5 293.9 299.5 Z"
      ></path>
      <path
        d="M 158.7 270 q 12.2 -2.2 26 -5.2 a 246.3 246.3 0 0 0 28.2 -8.1 v -10.4 A 43.2 43.2 0 0 0 211 234.1 a 33 33 0 0 0 -2.4 -5.9 a 31 31 0 0 0 -3.5 -5.2 a 30 30 0 0 0 -10.4 -7.9 a 35 35 0 0 0 -15.1 -3 a 93.2 93.2 0 0 0 -18 1.5 a 47.5 47.5 0 0 0 -15.3 6.2 a 32.9 32.9 0 0 1 14.9 4.5 q 6.2 3.9 6.2 13.6 a 32.3 32.3 0 0 1 -0.4 5.4 a 24 24 0 0 1 -2.3 7 a 21.2 21.2 0 0 1 -2.6 3.9 c -0.5 0.6 -1.1 1.2 -1.6 1.8 q -7 6.7 -23.1 6.7 c -8.8 0 -15.5 -1.6 -20.4 -4.6 a 139.5 139.5 0 0 0 2.2 22.8 a 74.3 74.3 0 0 1 17 -6.2 Q 146.4 272.2 158.7 270 Z"
      ></path>
      <path
        d="M 199.8 321.1 q 8.7 -6.4 13.4 -18.1 V 271.5 a 141.8 141.8 0 0 1 -14.8 9.3 c -1.6 0.8 -3.1 1.6 -4.5 2.3 q -8.9 4.2 -15.4 7.9 a 32.9 32.9 0 0 0 -10.2 8.6 q -3.7 4.9 -3.7 13.3 A 16.3 16.3 0 0 0 168.1 323 q 3.5 4.5 12.9 4.5 A 31.2 31.2 0 0 0 199.8 321.1 Z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: [Number, String],
      default: 48,
    },
    iconColor: {
      type: String,
      default: "#ffffff",
    },
  },
  computed: {
    arrowClass() {
      return this.loading === true ? "animated" : "";
    },
  },
};
</script>

<style>
.animated {
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1, 1);
  -moz-animation-name: spin;
  -moz-animation-duration: 1000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1, 1);
  -ms-animation-name: spin;
  -ms-animation-duration: 1000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1, 1);
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1, 1);
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(180deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(180deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
</style>
