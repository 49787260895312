import store from "@/store";
import gql from "graphql-tag";
import { parseIfJson, camelize, bg } from "@/utils/lib";
import { merge } from "lodash";

// TODO: fix
//const raiBeep = new Audio(require("@/assets/rai-notify.mp3"));
// I'm not sure how we will get from here to $sounds.play("beep");
const storeChannelName = () => store.getters["sockets/storeChannelName"];
const dialogMessageThread = () => store.getters["buys/dialogMessageThread"];

// Bind an event handler for text-message events
// on the pusher client
export const init = async (pusher, apollo) => {
  const channel = pusher.channel(storeChannelName());
  channel &&
    channel.bind("text-message", (data) => {
      const message = parseIfJson(data);
      // ** -----------------------------------------
      // *  Using `bg()` here, as none of these tasks
      // *  need to occur sequentially
      // ** -----------------------------------------
      try {
        bg(
          (message) => {
            handleMessageReceived(message);
          },
          [message]
        );
        bg(
          (message) => {
            addMessageToDialogThread(message);
          },
          [message]
        );
        bg(
          (message, apollo) => {
            addMessageToCache(camelize(message), apollo);
          },
          [message, apollo]
        );
      } catch (err) {
        window.raiPos.writeLog(
          `texting.js:47: ${JSON.stringify(data, message)}`
        );
      }
    });
};

// Toggle on the unread messages bubble
// Play the beep
const handleMessageReceived = (message) => {
  if (message.msg_type !== "received") return;

  store.commit("setUnreadMessagesExist", true);
  //raiBeep.play();
};

// Add the message to the current dialog thread (soon to be deprecated)
const addMessageToDialogThread = (message) => {
  if (message.message_thread_id === dialogMessageThread().id) {
    store.commit("buys/addDialogMessage", message);
  }
};

const ERROR_MESSAGES = {
  NOT_FOUND: `MessageThread not found in the cache`,
};

// Set the message as its thread's `lastMessage` and
// add it to the thread's `messages`
const addMessageToCache = async (message, apollo) => {
  const id = `MessageThread:${message.messageThreadId}`;
  const fragment = gql`
    fragment thread on MessageThread {
      unread
      lastMessage {
        id
      }
      messages {
        id
      }
    }
  `;
  // Try to find the message's thread in the cache
  try {
    const messageThreadFragment = apollo.readFragment({ id, fragment });
    if (!messageThreadFragment) throw new Error(ERROR_MESSAGES.NOT_FOUND);

    // Copy the thread
    const data = merge({}, messageThreadFragment, {
      unread: (message.msgType === "received" && true) || undefined,
    });
    // Prep the new message, ensuring it has a __typename field and its `id` fields are strings
    const newMessage = prepMessageForCache(message);

    // Set the thread's `lastMessage` to the newly received message
    data.lastMessage = newMessage;

    // Initialize data.messages to an empty array if it doesn't already exist
    if (!data.messages) {
      data.messages = [];
    }
    // If the message isn't already in the cache, add it
    if (!data.messages.find((m) => m.id === newMessage.id)) {
      data.messages = data.messages.concat(newMessage);
    }
    // Write back to the cache
    apollo.writeData({ id, data });
  } catch (error) {
    const shouldAddToTheCache = error.message === ERROR_MESSAGES.NOT_FOUND;

    // If the thread was found, but not all the fields were present (Invariant error)
    // just fetch the thread. Doing so will update the cache in place. Donezo!
    //
    // If the thread was NOT found, fetch it and add it to the TEXTING_VIEW_LIST cache
    //
    // ** NOTE **
    // The thing is, we _might_ want to just refetch queries here
    // instead of manually updating the cache.
    // As long as the only main query we're updaing is `TEXTING_VIEW_LIST`,
    // it'll be fine. Otherwise, it might become too much to handle.
    // Just something to keep in mind.
    //
    // ** UPDATE **
    // This is throwing lots of invariant errors.
    // Maybe the query isn't actually running to put the thread in the cache
    // Just going to comment this path out and let it fail for now.
    /*
    const {
      data: { messageThread }
    } = await fetchMessageThread(apollo, message.messageThreadId);

    //  Guards
    if (!messageThread) return;
    if (!shouldAddToTheCache) return;

    addMessageThreadToCache(
      apollo,
      prepMessageThreadForCache(messageThread, message)
    );
    */
    console.error("Message thread not in cache");
  }
};

// Ensures `message` has the right fields and field types
// to be successfully inserted into the cache
const prepMessageForCache = (message) => {
  return {
    __typename: "Message",
    ...message,
    id: message.id.toString(),
    messageThreadId: message.messageThreadId.toString(),
  };
};

// Fetches messageThread via Apollo
const fetchMessageThread = (apollo, messageThreadId) => {
  const { MESSAGE_THREAD } = require("@/views/Texting/AppView/graphql");

  return apollo.query({
    query: MESSAGE_THREAD,
    variables: {
      storeId: store.getters["auth/activeStoreId"],
      id: messageThreadId,
    },
  });
};

// Ensures `messageThread` is ready to be written to the cache
const prepMessageThreadForCache = (messageThread, message) => {
  messageThread.lastMessage = prepMessageForCache(message);
  messageThread.unread =
    (message.msgType === "received" && true) || messageThread.unread;
  return messageThread;
};

// Inserts `messageThread` into the TEXTING_VIEW_LIST cache
const addMessageThreadToCache = (apollo, messageThread) => {
  const { TEXTING_VIEW_LIST } = require("@/views/Texting/AppView/graphql");
  const textingViewListVariables = {
    storeId: store.getters["auth/activeStoreId"],
    input: { search: null },
  };

  // Read the query
  const messageThreadsQuery = apollo.readQuery({
    query: TEXTING_VIEW_LIST,
    variables: textingViewListVariables,
  });

  // Guard
  if (!messageThreadsQuery || !messageThreadsQuery.messageThreads.length)
    return;

  // Add the new thread as the head of `messageThreads`
  messageThreadsQuery.messageThreads = [messageThread].concat(
    messageThreadsQuery.messageThreads
  );

  // Write the query
  apollo.writeQuery({
    query: TEXTING_VIEW_LIST,
    variables: textingViewListVariables,
    data: messageThreadsQuery,
  });
};

export default {
  init,
};
