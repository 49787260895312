<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    class="rai-nav hidden-print-only"
    :mini-variant="$vuetify.breakpoint.mdOnly"
    mini-variant-width="64"
    :width="width"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :dark="$vuetify.breakpoint.mdOnly"
    :fixed="$vuetify.breakpoint.mdAndUp"
    :clipped="$vuetify.breakpoint.mdAndUp && false"
    touchless
    data-cy="navDesktop"
  >
    <template v-slot:prepend>
      <v-list-item :style="headerStyle">
        <rai-app-logo :color="appLogoColor" />
      </v-list-item>
      <v-divider></v-divider>
    </template>

    <rai-nav-list :store="store" />

    <rai-nav-extra-list v-if="$vuetify.breakpoint.xsOnly" />
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:append>
      <rai-nav-extra-list />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import RaiNavList from "@/components/RaiNavList.vue";
import RaiNavExtraList from "@/components/RaiNavExtraList.vue";
import RaiAppLogo from "@/components/util/RaiAppLogo";

export default {
  components: {
    RaiNavList,
    RaiNavExtraList,
    RaiAppLogo,
  },
  props: {
    store: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState({
      drawerExpanded: "drawerExpanded",
    }),
    appLogoColor() {
      return this.$vuetify.breakpoint.mdOnly
        ? "#fff"
        : this.$vuetify.theme.themes.light.primary;
    },
    spin() {
      return this.$store.getters["loading/loading"];
    },
    width() {
      return this.$vuetify.breakpoint.mdAndUp ? 218 : 256;
    },
    drawer: {
      get() {
        return this.$store.state.ui.mobileDrawer;
      },
      set(val) {
        this.$store.commit("ui/mobileDrawer", val);
      },
    },
    headerStyle() {
      return { maxHeight: `${this.$vuetify.application.top - 1}px` };
    },
  },
};
</script>

<style lang="scss">
.rai-nav.theme--dark {
  .v-icon {
    color: map-get($ui, "lighten-4");
  }
  .v-list-item:hover {
    .v-icon {
      color: map-get($shades, "white");
    }
  }
  .v-list-item--active:hover::before,
  .v-list-item--active::before {
    opacity: 0;
  }
  .v-list-item--active {
    background-color: var(--v-secondary-base);

    .v-icon {
      color: map-get($shades, "white");
    }
  }
}
.rai-nav.v-navigation-drawer--mini-variant.theme--dark {
  .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    .theme-dark.v-icon {
      color: map-get($ui, "lighten-3");
    }
  }
  .v-navigation-drawer--mini-variant.theme-dark {
    .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: map-get($ui, "lighten-3");
      .v-icon {
        color: map-get($ui, "lighten-3");
      }
    }
  }
}
.rai-nav.theme--dark.v-navigation-drawer--mini-variant {
  background-color: map-get($ui, "darken-6");
}
.rai-nav.theme--light {
  background-color: map-get($ui, "lighten-5");
}
.v-navigation-drawer--open .v-list__tile--link {
  padding-left: 12px;
  padding-right: 12px;
}
.v-navigation-drawer--mini-variant .v-list__tile--link {
  padding-left: 6px;
  padding-right: 6px;
}
.v-navigation-drawer--mini-variant .v-toolbar__content {
  padding-left: 18px;
  padding-right: 14px;
}
.v-navigation-drawer--open .v-list__tile--link .v-list__tile__action {
  min-width: 48px;
  width: 48px;
  justify-content: center;
}
</style>
